// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

require("integrations/setup-hotjar");
require("integrations/setup-refiner");
require("integrations/setup-segment");

require("chartkick/chart.js");

require("sprinkles/add-domain");
require("sprinkles/asset-overview-charts");
require("sprinkles/budget-form");
require("sprinkles/bulk-invite-form");
require("sprinkles/cart");
require("sprinkles/data-table-detail");
require("sprinkles/delete-company-form");
require("sprinkles/dropdown");
require("sprinkles/dustin-sign-up");
require("sprinkles/employment-form");
require("sprinkles/equipment-item-form");
require("sprinkles/feature-description");
require("sprinkles/file-attachment");
require("sprinkles/file-upload");
require("sprinkles/filter-form");
require("sprinkles/flash");
require("sprinkles/gear-selection-form");
require("sprinkles/lazy-load-table-details");
require("sprinkles/lazy-refresh");
require("sprinkles/modal");
require("sprinkles/more-action");
require("sprinkles/morph-form-from");
require("sprinkles/nav-mobile");
require("sprinkles/nav-store-menu");
require("sprinkles/nav-store-search");
require("sprinkles/new-employments-page");
require("sprinkles/payor-options");
require("sprinkles/policy-form");
require("sprinkles/popout");
require("sprinkles/price-breakdown");
require("sprinkles/product-request-modal");
require("sprinkles/regenerate-scim-token");
require("sprinkles/scroll-top");
require("sprinkles/searchable-dropdowns");
require("sprinkles/shipping-info");
require("sprinkles/show-admin-service-accounts-page");
require("sprinkles/show-product-page");
require("sprinkles/side-panel");
require("sprinkles/favorite-product");
require("sprinkles/software-plan-form");
require("sprinkles/software-plan-table");
require("sprinkles/sso-step");
require("sprinkles/starter-kit-form");
require("sprinkles/store-modal");
require("sprinkles/submit-and-disable");
require("sprinkles/submit-form");
require("sprinkles/tabs");
require("sprinkles/toggle-disabled");
require("sprinkles/toggle-visibility");
require("sprinkles/tutorial-steps");
require("sprinkles/company-settings-page");
require("sprinkles/disable-button");
require("sprinkles/disable-inputs");
require("sprinkles/toggle-checked");
require("sprinkles/filter-table");
require("sprinkles/edit-assignments");
require("sprinkles/mark-as-received-form");
require("sprinkles/software-bulk-plan");
require("sprinkles/stock-levels");
require("sprinkles/confirm-overage");
require("sprinkles/deliveries-survey");
require("sprinkles/copy-text-to-clipboard");
require("sprinkles/simple-tooltip");
require("sprinkles/teams");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
